export class GameTheme {
	public readonly name: string;

	private constructor(name: string) {
        this.name = name;
	}

    public static readonly SciFi = new GameTheme("Sci-fi");
    public static readonly Fantasy = new GameTheme("Fantasy");
    public static readonly SciFiFantasy = new GameTheme("Sci-fi / Fantasy");
    public static readonly Zombies = new GameTheme("Zombies");
    public static readonly PostApocalytpic = new GameTheme("Post-Apocalytpic");
    public static readonly Ancient = new GameTheme("Ancient");
    public static readonly Medieval = new GameTheme("Medieval");
    public static readonly Modern = new GameTheme("Modern");
    public static readonly History = new GameTheme("History");
    public static readonly Space = new GameTheme("Space");
    public static readonly Pirates = new GameTheme("Pirates");
    public static readonly Industry = new GameTheme("Industry");
    public static readonly Farming = new GameTheme("Farming");
    public static readonly Car = new GameTheme("Car");
    public static readonly Plane = new GameTheme("Plane");
    public static readonly Trains = new GameTheme("Trains");
    public static readonly Tanks = new GameTheme("Tanks");
    public static readonly Cooking = new GameTheme("Cooking");
    public static readonly Nature = new GameTheme("Nature");
    public static readonly Music = new GameTheme("Music");
    public static readonly Medical = new GameTheme("Medical");
    public static readonly Creature = new GameTheme("Creature");
    public static readonly Fashion = new GameTheme("Fashion");

    public static getAllThemes(): GameTheme[] {
        return [
            GameTheme.SciFi,
            GameTheme.Fantasy,
            GameTheme.SciFiFantasy,
            GameTheme.Zombies,
            GameTheme.PostApocalytpic,
            GameTheme.Ancient,
            GameTheme.Medieval,
            GameTheme.Modern,
            GameTheme.History,
            GameTheme.Space,
            GameTheme.Pirates,
            GameTheme.Industry,
            GameTheme.Farming,
            GameTheme.Car,
            GameTheme.Plane,
            GameTheme.Trains,
            GameTheme.Tanks,
            GameTheme.Cooking,
            GameTheme.Nature,
            GameTheme.Music,
            GameTheme.Medical,
            GameTheme.Creature,
            GameTheme.Fashion,
        ];
    }
}