export class PhasePortionMeta {
    public name: string;

    private constructor(name: string) {
        this.name = name;
    }

    public static readonly Phase1_DevPortion = new PhasePortionMeta("Tech Prototyping");
    public static readonly Phase1_DesignPortion = new PhasePortionMeta("Design Prototyping");
    public static readonly Phase1_WritingPortion = new PhasePortionMeta("Storyboarding");

    public static readonly Phase2_DevPortion = new PhasePortionMeta("Programming");
    public static readonly Phase2_DesignPortion = new PhasePortionMeta("Designing");
    public static readonly Phase2_WritingPortion = new PhasePortionMeta("Writing");

    public static readonly Phase3_DevPortion = new PhasePortionMeta("Bug Fixing");
    public static readonly Phase3_DesignPortion = new PhasePortionMeta("Polishing");
    public static readonly Phase3_WritingPortion = new PhasePortionMeta("Editing");
}