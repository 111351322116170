import { State } from "@/store";
import { Commit } from "vuex";
import { Utils } from "../General/Utils";
import { Message } from "./Message";
import { MessageTriggerEventType } from "./MessageTriggerEventType";

export class MessageTrigger {
    public message: Message;
    public condition: (rootState: State) => boolean;

    public constructor(message: Message, condition: (rootState: State) => boolean) {
        this.message = message;
        this.condition = condition;
    }

    // TODO: So I was thinking with this approach I could add a bunch of messages with different triggers and, when the trigger is satisfied, I could fire the message.
    // But this kinda sucks. This would require lots of busy evaluating which isn't great. It doesn't scale well if there end up being hundreds or thousands of messages.
    // I think instead I'd want to have some kind of trigger that only fires when something happens.
    // But THAT sucks too because then I have to define messages at exactly the right place. Maybe that's okay? I'm not sure.
    // Is it crazy to have certain conditions that cause all the message triggers to get evaluated ... like after a game is complete, after a new phase is started, etc.
    // Each of them could fire an event and message triggers could be registered to that event?
    //
    // What's the simplest solution? If you just fire messages manually, are you gonna run into trouble? How would you fire them?

    private static triggersByEventType = new Map<MessageTriggerEventType, MessageTrigger[]>(
        [
            [MessageTriggerEventType.GamePhaseComplete, [
                new MessageTrigger(
                    new Message(
                        "You completed a phase!",
                        "You did it! You completed a phase of the game! Oh man I can't believe how amazing you are in every way! Seriously! I think I love you!"
                    ),
                    (rootState) => {
                        return true;
                    },
                ),
            ]],
            [MessageTriggerEventType.GameComplete, [
                new MessageTrigger(
                    new Message(
                        "Wow! A whole game!",
                        "You've finished your very first game! This, of course, means you are a genius of legendary proportions and there's nothing else in this game for you. You win everything. Every. Thing."
                    ),
                    (rootState) => {
                        return true;
                    },
                ),
            ]],
        ]);

    public static fireConditions(messageTriggerEventType :MessageTriggerEventType, state: State, commit: Commit): void {
        const messageTriggers = this.triggersByEventType.get(messageTriggerEventType);
        if (!messageTriggers) { return; }

        const messageTriggersToRemove :MessageTrigger[] = [];
        for (const messageTrigger of messageTriggers) {
            if (messageTrigger.condition(state)) {
                commit('addMessage', messageTrigger.message);
                messageTriggersToRemove.push(messageTrigger);
            }
        }

        // Make sure we can't trigger the same message twice:
        for (const messageTrigger of messageTriggersToRemove) {
            Utils.ArrayRemove(messageTriggers, messageTrigger);
        }
    }
}