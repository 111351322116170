
import { Vue, Options } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import GoogleAd from '@/components/GoogleAd.vue';

@Options({
  components: {
    GoogleAd,
  },
})
export default class App extends Vue {
  get currentRouteName() {
    return this.$route.name;
  }

  get isDebugMode() {
    return process.env.NODE_ENV === 'development';
  }

  get isFullPageMode() {
    return this.currentRouteName === 'Spritecraft' || this.currentRouteName === 'TheMostDifficultGame' || this.currentRouteName === 'TheZoinksMakeAGame';
  }

  get isShowNavBar() {
    return this.currentRouteName !== 'TheZoinksMakeAGame';
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(route: any) {
    const title = this.$route.meta.title as string ?? "Autosaved; A place for Christian geeks";
    const description = this.$route.meta.description as string ?? "Autosaved is a website where Christian nerds Robbie Mahair and Chris Krentz, a couple of Christian nerds, create Minecraft tools, Factorio blueprints, fiction books, and whatever else we feel like creating!";

    // Set the title of the page based on the route metadata:
    document.title = title;

    // Set the description of the page based on the route metadata:
    document.querySelector('meta[name="description"]')!.setAttribute("content", description);
  }
}

