export class PhaseProgressData {
    public gameId: number;
    public phaseNumber: number;
    public progress: number;

    public constructor(gameId: number, phaseNumber: number, progress: number) {
        this.gameId = gameId;
        this.phaseNumber = phaseNumber;
        this.progress = progress;
    }
}