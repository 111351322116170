import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store, key } from './store';
import './assets/styles/site.css';
import VueGtag from "vue-gtag-next";

const theApp = createApp(App);

// Google analytics:
theApp.use(VueGtag, {
    property: {
        id: "UA-146872237-1"
    }
});

theApp.use(router).use(store, key).mount('#app');

theApp.directive('click-outside', {
    mounted(el, binding: any, vnode: any) {
        el.clickOutsideEvent = function (event: MouseEvent) {
            // TODO: This doesn't seem to work ... and it also doesn't seem necessary?
            //       I could swear it mattered in the old project but since I can't get that to run anymore ... *shrug*?
            // // here I check that click was outside the el and his children
            // if (!(el == event.target || el.contains(event.target))) {
                
            //     // and if it did, call method provided in attribute value
            //     vnode.context[binding.expression](event);
            // }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unmounted (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});