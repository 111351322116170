export class Utils {
    public static GetProgress(startTime: number, currentTime: number, timeNeeded: number) {
        let updatedProgress = (currentTime - startTime) / (timeNeeded * 1000);
        updatedProgress = updatedProgress > 1 ? 1 : updatedProgress < 0 ? 0 : updatedProgress;
        return updatedProgress;
    }

    public static ArrayRemove<T>(array: T[], toRemove: T): void {
        const index = array.indexOf(toRemove);
        if (index > -1) {
            array.splice(index, 1);
        }
    }
}