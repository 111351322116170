<template>
  <ins
    class="adsbygoogle"
    data-ad-client="ca-pub-8235320715401138"
    :data-ad-slot="adSlot"
    :style="responsiveMode ? 'display:block' : `display:inline-block;width:${adWidth}px;height:${adHeight}px;`"
    :data-ad-format="responsiveMode ? 'auto' : null"
    :data-full-width-responsive="responsiveMode ? 'true' : null"
  >
  </ins>
</template>


<script lang="js">

export default {
  name: "GoogleAd",
  props: {
    responsiveMode: Boolean,
    adSlot: String,
    adHeight: Number,
    adWidth: Number,
  },
  data() {
    return {
      googleInit: null,
    };
  },
  mounted() {
    let timeout = 200;
    this.googleInit = setTimeout(() => {
      if (typeof window !== "undefined") {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    }, timeout);
  },
  unmounted() {
    if (this.googleInit) clearTimeout(this.googleInit);
  }
};

</script>