import { Module } from 'vuex';
import { Employee } from '@/logic/Zoinks/Employees/Employee';
import { EmployeeSearchTypeEnum } from '@/logic/Zoinks/Employees/EmployeeSearchTypeEnum';
import { EmployeeSearchType } from '@/logic/Zoinks/Employees/EmployeeSearchType';
import { RecruitingLevel } from '@/logic/Zoinks/Employees/RecuitingLevel';
import { EmployeeSearchTypeHelper } from '@/logic/Zoinks/Employees/EmployeeSearchTypeHelper';
import { EmployeeCandidateHelper } from '@/logic/Zoinks/Employees/EmployeeCandidateHelper';
import { Utils } from '@/logic/Zoinks/General/Utils';

export interface EmployeesState {
  employees: Employee[],
  maxEmployees: number,
  activeSearchType: EmployeeSearchTypeEnum,
  lastSearchType: EmployeeSearchTypeEnum,
  allSearchTypesMap: Map<EmployeeSearchTypeEnum, EmployeeSearchType>,
  recruitingLevel: RecruitingLevel,
  activeSearchProgress: number,
  activeSearchStartTime: number,
  candidates: Employee[],
}

const employeesModule: Module<EmployeesState, any> = {
  state: {
    employees: [],
    maxEmployees: 1,
    activeSearchType: EmployeeSearchTypeEnum.NoActiveSearch,
    lastSearchType: EmployeeSearchTypeEnum.StreetCorner,
    allSearchTypesMap: EmployeeSearchType.getEmployeeSearchTypesMap(),
    recruitingLevel: RecruitingLevel.None,
    activeSearchProgress: 0,
    activeSearchStartTime: 0,
    candidates: [],
  },
  mutations: {
    setActiveSearchType(state, searchType: EmployeeSearchTypeEnum) {
      state.activeSearchType = searchType;
      state.activeSearchProgress = 0;
      state.activeSearchStartTime = Date.now();
    },
    setLastSearchType(state, searchType: EmployeeSearchTypeEnum) {
      state.lastSearchType = searchType;
    },
    setActiveSearchProgress(state, updatedProgress: number) {
      state.activeSearchProgress = updatedProgress;
    },
    setActiveSearchStartTime(state, startTime: number) {
      state.activeSearchStartTime = startTime;
    },
    setCandidates(state, candidates: Employee[]) {
      state.candidates = candidates;
    },
    hireCandidate(state, candidateIndex :number) {
      const candidates = state.candidates.splice(candidateIndex, 1);
      state.employees.push(candidates[0]);
    },
    removeEmployee(state, employeeId: number) {
      state.employees.splice(state.employees.findIndex(e => e.id === employeeId), 1);
    }
  },
  actions: {
    employeesTick( { state, commit }, now: number) {
      if (EmployeeSearchTypeHelper.IsActiveSearch(state.activeSearchType) && state.activeSearchProgress < 1) {
        if (now < state.activeSearchStartTime) {
          // This should never happen
          commit("setActiveSearchStartTime", now);
        }
        const updatedProgress = Utils.GetProgress(state.activeSearchStartTime, now, state.allSearchTypesMap.get(state.activeSearchType)!.duration);
        commit("setActiveSearchProgress", updatedProgress);

        if (updatedProgress === 1) {
          // populate the potential candidates
          const candidates = EmployeeCandidateHelper.getEmployeeCandidates(state.activeSearchType);
          commit("setCandidates", candidates);
        }
      }
    },
    hireCandidate( { state, commit }, candidateIndex: number) {
      commit("hireCandidate", candidateIndex);
      if (state.employees.length >= state.maxEmployees) {
        commit("setActiveSearchType", EmployeeSearchTypeEnum.NoActiveSearch);
      }
    },
    fireEmployee( { commit, dispatch }, employeeId: number) {
      dispatch("removeEmployeeFromActivePhase", employeeId);
      commit("removeEmployee", employeeId);
    },
  },
}

export default employeesModule;