export class Employee {
	public readonly id: number;

	public readonly name: string;
    public readonly isMale: boolean;
    public readonly isHuman: boolean;
    public readonly searchDescription: string;

    public devSkill: number;
    public devPotential: number;

    public designSkill: number;
    public designPotential: number;

    public writingSkill: number;
    public writingPotential: number;
    
    public zoinkSkill: number;
    public zoinkPotential: number;

    private static lastId = 0;

    public constructor(name: string, isMale: boolean, isHuman: boolean, searchDescription: string, devSkill: number, devPotential: number, designSkill: number, designPotential: number, writingSkill: number, writingPotential: number, zoinkSkill: number, zoinkPotential: number)
    {
        Employee.lastId++;
        this.id = Employee.lastId;

        this.name = name;
        this.isMale = isMale;
        this.isHuman = isHuman;
        this.searchDescription = searchDescription;

        this.devSkill = devSkill;
        this.devPotential = devPotential;

        this.designSkill = designSkill;
        this.designPotential = designPotential;

        this.writingSkill = writingSkill;
        this.writingPotential = writingPotential;
        
        this.zoinkSkill = isHuman ? 0 : zoinkSkill;
        this.zoinkPotential = isHuman ? 0 : zoinkPotential;
    }
}