import { createStore, useStore as baseUseStore, Store } from 'vuex';
import { InjectionKey } from 'vue';
import gamesModule, { GamesState } from './Zoinks/GamesModule';
import employeesModule, { EmployeesState } from './Zoinks/EmployeesModule';
import generalModule, { GeneralState } from './Zoinks/GeneralModule';
import messagesModule, { MessagesState } from './Zoinks/MessagesModule';

export interface State {
  generalModule: GeneralState,
  gamesModule: GamesState,
  employeesModule: EmployeesState,
  messagesModule: MessagesState,
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  modules: {
    generalModule: generalModule,
    gamesModule: gamesModule,
    employeesModule: employeesModule,
    messagesModule: messagesModule,
  }
})

// define your own `useStore` composition function
export function useStore () {
  return baseUseStore(key)
}