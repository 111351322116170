export class GameMechanic {
	public readonly name: string;

	private constructor(name: string) {
        this.name = name;
	}

    public static readonly ActionBeatEmUp = new GameMechanic("Action: Beat 'em Up");
    public static readonly ActionFlying = new GameMechanic("Action: Flying");
    public static readonly ActionFighting = new GameMechanic("Action: Fighting");
    public static readonly ActionPlatformer = new GameMechanic("Action: Platformer");
    public static readonly ActionRacing = new GameMechanic("Action: Racing");
    public static readonly ActionShooter = new GameMechanic("Action: Shooter");
    public static readonly ActionTiming = new GameMechanic("Action: Timing");
    public static readonly ActionTyping = new GameMechanic("Action: Typing");
    public static readonly Adventure = new GameMechanic("Adventure");
    public static readonly Incremental = new GameMechanic("Incremental");
    public static readonly LifeManagement = new GameMechanic("Life Management");
    public static readonly Puzzle = new GameMechanic("Puzzle");
    public static readonly SettlementManagement = new GameMechanic("Settlement Management");
    public static readonly Sandbox = new GameMechanic("Sandbox");
    public static readonly StrategyEmpireBuilding = new GameMechanic("Strategy: Empire-building (4x)");
    public static readonly StrategyRealTime = new GameMechanic("Strategy: Real-time");
    public static readonly StrategyTimeOptimization = new GameMechanic("Strategy: Time-optimization");
    public static readonly StrategyTurnBased = new GameMechanic("Strategy: Turn-based");

    public static getAllMechanics(): GameMechanic[] {
        return [
            GameMechanic.ActionBeatEmUp,
            GameMechanic.ActionFlying,
            GameMechanic.ActionFighting,
            GameMechanic.ActionPlatformer,
            GameMechanic.ActionRacing,
            GameMechanic.ActionShooter,
            GameMechanic.ActionTiming,
            GameMechanic.ActionTyping,
            GameMechanic.Adventure,
            GameMechanic.Incremental,
            GameMechanic.LifeManagement,
            GameMechanic.Puzzle,
            GameMechanic.SettlementManagement,
            GameMechanic.Sandbox,
            GameMechanic.StrategyEmpireBuilding,
            GameMechanic.StrategyRealTime,
            GameMechanic.StrategyTimeOptimization,
            GameMechanic.StrategyTurnBased,
        ];
    }
}