import { Constants } from "../General/Constants";
import { Employee } from "./Employee";
import { EmployeeSearchTypeEnum } from "./EmployeeSearchTypeEnum";

class Person {
    public readonly name: string;
    public readonly isMale: boolean;
    public readonly isHuman: boolean;

    public constructor(name: string, isMale: boolean, isHuman: boolean) {
        this.name = name;
        this.isMale = isMale;
        this.isHuman = isHuman;
    }
}

class SkillProfile {
    public skillMean = 0;
    public skillStdev = 0;
    public potentialMean = 0;
    public potentialStdev = 0;
}

class SkillProfileSet {
    public devSkillProfile = new SkillProfile();
    public designSkillProfile = new SkillProfile();
    public writingSkillProfile = new SkillProfile();
    public zoinkSkillProfile = new SkillProfile();
}

export class EmployeeCandidateHelper {
    public static getEmployeeCandidates(searchType: EmployeeSearchTypeEnum): Employee[] {
        if (searchType === EmployeeSearchTypeEnum.StreetCorner) {
            return this.getStreetCornerEmployeeCandidates();
        } else if (searchType === EmployeeSearchTypeEnum.CheckWithFriends) {
            return this.getCheckWithFriendsEmployeeCandidates();
        }
        return [];
    }

    private static getStreetCornerEmployeeCandidates(): Employee[] {
        return [
            this.generateEmployeeCandidate(
                this.getRandomHuman(),
                this.getHumanStreetCornerSkillProfileSet(),
                this.getRandomEntry(this.allCornerDescriptions)
            ),
            this.generateEmployeeCandidate(
                this.getRandomZoink(),
                this.getZoinkStreetCornerSkillProfileSet(),
                this.getRandomEntry(this.allCornerDescriptions)
            ),
        ];
    }

    private static getCheckWithFriendsEmployeeCandidates(): Employee[] {
        return [
            this.generateEmployeeCandidate(
                this.getRandomHuman(),
                this.getHumanFriendsSkillProfileSet(),
                this.getRandomEntry(this.allHumanFriendDescriptions)
            ),
            this.generateEmployeeCandidate(
                this.getRandomZoink(),
                this.getZoinkFriendsSkillProfileSet(),
                this.getRandomEntry(this.allZoinkFriendDescriptions)
            ),
        ];
    }

    private static getHumanStreetCornerSkillProfileSet(): SkillProfileSet {
        const skillProfileSet = new SkillProfileSet();
        
        skillProfileSet.devSkillProfile.skillMean = Constants.SKILL_MEAN_STREETCORNER;
        skillProfileSet.devSkillProfile.skillStdev = Constants.SKILL_STDEV_STREETCORNER;
        skillProfileSet.devSkillProfile.potentialMean = Constants.POTENTIAL_MEAN_STREETCORNER;
        skillProfileSet.devSkillProfile.potentialStdev = Constants.POTENTIAL_STDEV_STREETCORNER;
        
        skillProfileSet.designSkillProfile.skillMean = Constants.SKILL_MEAN_STREETCORNER;
        skillProfileSet.designSkillProfile.skillStdev = Constants.SKILL_STDEV_STREETCORNER;
        skillProfileSet.designSkillProfile.potentialMean = Constants.POTENTIAL_MEAN_STREETCORNER;
        skillProfileSet.designSkillProfile.potentialStdev = Constants.POTENTIAL_STDEV_STREETCORNER;
        
        skillProfileSet.writingSkillProfile.skillMean = Constants.SKILL_MEAN_STREETCORNER;
        skillProfileSet.writingSkillProfile.skillStdev = Constants.SKILL_STDEV_STREETCORNER;
        skillProfileSet.writingSkillProfile.potentialMean = Constants.POTENTIAL_MEAN_STREETCORNER;
        skillProfileSet.writingSkillProfile.potentialStdev = Constants.POTENTIAL_STDEV_STREETCORNER;
        
        skillProfileSet.zoinkSkillProfile.skillMean = 0;
        skillProfileSet.zoinkSkillProfile.skillStdev = 0;
        skillProfileSet.zoinkSkillProfile.potentialMean = 0;
        skillProfileSet.zoinkSkillProfile.potentialStdev = 0;

        return skillProfileSet;
    }

    private static getZoinkStreetCornerSkillProfileSet(): SkillProfileSet {
        const skillProfileSet = this.getHumanStreetCornerSkillProfileSet();

        this.zoinkItDownSet(skillProfileSet);
        
        skillProfileSet.zoinkSkillProfile.skillMean = Constants.SKILL_MEAN_STREETCORNER;
        skillProfileSet.zoinkSkillProfile.skillStdev = Constants.SKILL_STDEV_STREETCORNER;
        skillProfileSet.zoinkSkillProfile.potentialMean = Constants.POTENTIAL_MEAN_STREETCORNER;
        skillProfileSet.zoinkSkillProfile.potentialStdev = Constants.POTENTIAL_STDEV_STREETCORNER;

        return skillProfileSet;
    }

    private static getHumanFriendsSkillProfileSet(): SkillProfileSet {
        const skillProfileSet = new SkillProfileSet();
        
        skillProfileSet.devSkillProfile.skillMean = Constants.SKILL_MEAN_FRIENDS;
        skillProfileSet.devSkillProfile.skillStdev = Constants.SKILL_STDEV_FRIENDS;
        skillProfileSet.devSkillProfile.potentialMean = Constants.POTENTIAL_MEAN_FRIENDS;
        skillProfileSet.devSkillProfile.potentialStdev = Constants.POTENTIAL_STDEV_FRIENDS;
        
        skillProfileSet.designSkillProfile.skillMean = Constants.SKILL_MEAN_FRIENDS;
        skillProfileSet.designSkillProfile.skillStdev = Constants.SKILL_STDEV_FRIENDS;
        skillProfileSet.designSkillProfile.potentialMean = Constants.POTENTIAL_MEAN_FRIENDS;
        skillProfileSet.designSkillProfile.potentialStdev = Constants.POTENTIAL_STDEV_FRIENDS;
        
        skillProfileSet.writingSkillProfile.skillMean = Constants.SKILL_MEAN_FRIENDS;
        skillProfileSet.writingSkillProfile.skillStdev = Constants.SKILL_STDEV_FRIENDS;
        skillProfileSet.writingSkillProfile.potentialMean = Constants.POTENTIAL_MEAN_FRIENDS;
        skillProfileSet.writingSkillProfile.potentialStdev = Constants.POTENTIAL_STDEV_FRIENDS;
        
        skillProfileSet.zoinkSkillProfile.skillMean = 0;
        skillProfileSet.zoinkSkillProfile.skillStdev = 0;
        skillProfileSet.zoinkSkillProfile.potentialMean = 0;
        skillProfileSet.zoinkSkillProfile.potentialStdev = 0;

        return skillProfileSet;
    }

    private static getZoinkFriendsSkillProfileSet(): SkillProfileSet {
        const skillProfileSet = this.getHumanFriendsSkillProfileSet();

        this.zoinkItDownSet(skillProfileSet);
        
        skillProfileSet.zoinkSkillProfile.skillMean = Constants.SKILL_MEAN_FRIENDS;
        skillProfileSet.zoinkSkillProfile.skillStdev = Constants.SKILL_STDEV_FRIENDS;
        skillProfileSet.zoinkSkillProfile.potentialMean = Constants.POTENTIAL_MEAN_FRIENDS;
        skillProfileSet.zoinkSkillProfile.potentialStdev = Constants.POTENTIAL_STDEV_FRIENDS;

        return skillProfileSet;
    }

    private static zoinkItDownSet(skillProfileSet: SkillProfileSet): void {
        // Cuts down all "normal" skills by a factor to make up for the additional "zoink" skill.
        this.zoinkItDown(skillProfileSet.devSkillProfile);
        this.zoinkItDown(skillProfileSet.designSkillProfile);
        this.zoinkItDown(skillProfileSet.writingSkillProfile);
    }

    private static zoinkItDown(skillProfile: SkillProfile): void {
        skillProfile.skillMean = skillProfile.skillMean * Constants.ZOINK_SKILL_MODIFIER;
        //skillProfile.skillStdev = skillProfile.skillStdev * Constants.ZOINK_SKILL_MODIFIER;
        skillProfile.potentialMean = skillProfile.potentialMean * Constants.ZOINK_SKILL_MODIFIER;
        //skillProfile.potentialStdev = skillProfile.potentialStdev * Constants.ZOINK_SKILL_MODIFIER;
    }

    private static generateEmployeeCandidate(person: Person, skillProfileSet: SkillProfileSet, searchDescription: string): Employee {
        return new Employee(
            person.name,
            person.isMale,
            person.isHuman,
            this.modifyGenderWords(searchDescription, person.isMale),
            this.randomSkillValue(skillProfileSet.devSkillProfile),
            this.randomPotentialValue(skillProfileSet.devSkillProfile),
            this.randomSkillValue(skillProfileSet.designSkillProfile),
            this.randomPotentialValue(skillProfileSet.designSkillProfile),
            this.randomSkillValue(skillProfileSet.writingSkillProfile),
            this.randomPotentialValue(skillProfileSet.writingSkillProfile),
            this.randomSkillValue(skillProfileSet.zoinkSkillProfile),
            this.randomPotentialValue(skillProfileSet.zoinkSkillProfile)
        );
    }

    private static modifyGenderWords(description: string, isMale: boolean): string {
        // There's almost surely a simpler way to do this, but it works:
        while (description.indexOf('|') > -1) {
            const index1 = description.indexOf('|');
            const index2 = description.indexOf('|', index1 + 1);
            const first = description.substring(0, index1);
            const middle = description.substring(index1 + 1, index2).split('/')[isMale ? 0 : 1];
            const last = description.substring(index2 + 1, description.length);
            description = first + middle + last;
        }
        return description;
    }

    private static randomSkillValue(skillProfile: SkillProfile): number {
        return this.randomWholeNumberValue(skillProfile.skillMean, skillProfile.skillStdev, Constants.SKILL_MAX, Constants.SKILL_MIN);
    }

    private static randomPotentialValue(skillProfile: SkillProfile): number {
        return this.randomWholeNumberValue(skillProfile.potentialMean, skillProfile.potentialStdev, Constants.POTENTIAL_MAX, Constants.POTENTIAL_MIN);
    }

    private static randomWholeNumberValue(mean: number, stdev: number, max: number, min: number) {
        const random = this.gaussianRandom(mean, stdev);
        return random < min ? min : random > max ? max : Math.round(random);
    }

    private static gaussianRandom(mean: number, stdev: number) {
        const u = 1 - Math.random(); //Converting [0,1) to (0,1)
        const v = Math.random();
        const z = Math.sqrt( -2.0 * Math.log( u ) ) * Math.cos( 2.0 * Math.PI * v );
        // Transform to the desired mean and standard deviation:
        return z * stdev + mean;
    }

    private static getRandomPerson(): Person {
        if (Math.random() < 0.5) {
            return this.getRandomHuman();
        } else {
            return this.getRandomZoink();
        }
    }

    private static getRandomHuman(): Person {
        if (Math.random() < 0.5) {
            return new Person(this.getRandomEntry(this.allHumanMaleNames) + " " + this.getRandomEntry(this.allHumanLastNames), true, true);
        } else {
            return new Person(this.getRandomEntry(this.allHumanFemaleNames) + " " + this.getRandomEntry(this.allHumanLastNames), false, true);
        }
    }

    private static getRandomZoink(): Person {
        if (Math.random() < 0.5) {
            return new Person(this.getRandomEntry(this.allZoinkMaleNames) + " " + this.getRandomEntry(this.allZoinkLastNames), true, false);
        } else {
            return new Person(this.getRandomEntry(this.allZoinkFemaleNames) + " " + this.getRandomEntry(this.allZoinkLastNames), false, false);
        }
    }

    private static getRandomEntry(entries: string[]): string {
        return entries[this.getRandomInt(entries.length)];
    }

    private static getRandomInt(max: number): number {
        return Math.floor(Math.random() * max);
    }

    private static allCornerDescriptions: string[] = [
        "You found |him/her| by the bus stop. |He/She| seems nice enough.",
        "You ran into |him/her| on the subway and |he/she| didn't try to rob you or anything!",
        "|He/She| has a college degree from Totally Real Online University Co.",
    ];

    private static allZoinkFriendDescriptions: string[] = [
        "|He/She|'s your |brother/sister|-in-law; your |sister/brother| says |he/she| really needs this job.",
        "You met this |guy/gal| on the spaceship over and really hit it off!",
        "\"I'm so excited to finally get my first normal human job!\""
    ];

    private static allHumanFriendDescriptions: string[] = [
        "You met |him/her| in the super market the other day and |he/she| mentioned |he/she|'d love to get a job anywhere away from |his/her| kids.",
        "Your neighbor in apartment 3-B. |He/She|'s reasonably quiet, so at least |he/she| won't distract the other employees.",
    ];

    private static allZoinkMaleNames: string[] = [
        "Lurd",
        "Arastiflac",
        "Sternordi",
    ];

    private static allZoinkFemaleNames: string[] = [
        "Shreenareck",
        "Shresterall",
        "Purmani",
    ];

    private static allZoinkLastNames: string[] = [
        "Smith",
    ]

    private static allHumanMaleNames: string[] = [
        "James",
        "John",
        "Robert",
        "Michael",
        "William",
        "David",
        "Richard",
        "Charles",
        "Joseph",
        "Thomas",
        "Christopher",
        "Daniel",
        "Paul",
        "Mark",
        "Donald",
        "George",
        "Kenneth",
        "Steven",
        "Edward",
        "Brian",
        "Ronald",
        "Anthony",
        "Kevin",
        "Jason",
        "Matthew",
        "Gary",
        "Timothy",
        "Jose",
        "Larry",
        "Jeffrey",
        "Frank",
        "Scott",
        "Eric",
        "Stephen",
        "Andrew",
        "Raymond",
        "Gregory",
        "Joshua",
        "Jerry",
        "Dennis",
        "Walter",
        "Patrick",
        "Peter",
        "Harold",
        "Douglas",
        "Henry",
        "Carl",
        "Arthur",
        "Ryan",
        "Roger",
        "Joe",
        "Juan",
        "Jack",
        "Albert",
        "Jonathan",
        "Justin",
        "Terry",
        "Gerald",
        "Keith",
        "Samuel",
        "Willie",
        "Ralph",
        "Lawrence",
        "Nicholas",
        "Roy",
        "Benjamin",
        "Bruce",
        "Brandon",
        "Adam",
        "Harry",
        "Fred",
        "Wayne",
        "Billy",
        "Steve",
        "Louis",
        "Jeremy",
        "Aaron",
        "Randy",
        "Howard",
        "Eugene",
        "Carlos",
        "Russell",
        "Bobby",
        "Victor",
        "Martin",
        "Ernest",
        "Phillip",
        "Todd",
        "Jesse",
        "Craig",
        "Alan",
        "Shawn",
        "Clarence",
        "Sean",
        "Philip",
        "Chris",
        "Johnny",
        "Earl",
        "Jimmy",
        "Antonio",
        "Danny",
        "Bryan",
        "Tony",
        "Luis",
        "Mike",
        "Stanley",
        "Leonard",
        "Nathan",
        "Dale",
        "Manuel",
        "Rodney",
        "Curtis",
        "Norman",
        "Allen",
        "Marvin",
        "Vincent",
        "Glenn",
        "Jeffery",
        "Travis",
        "Jeff",
        "Chad",
        "Jacob",
        "Lee",
        "Melvin",
        "Alfred",
        "Kyle",
        "Francis",
        "Bradley",
        "Jesus",
        "Herbert",
        "Frederick",
        "Ray",
        "Joel",
        "Edwin",
        "Don",
        "Eddie",
        "Ricky",
        "Troy",
        "Randall",
        "Barry",
        "Alexander",
        "Bernard",
        "Mario",
        "Leroy",
        "Francisco",
        "Marcus",
        "Micheal",
        "Theodore",
        "Clifford",
        "Miguel",
        "Oscar",
        "Jay",
        "Jim",
        "Tom",
        "Calvin",
        "Alex",
        "Jon",
        "Ronnie",
        "Bill",
        "Lloyd",
        "Tommy",
        "Leon",
        "Derek",
        "Warren",
        "Darrell",
        "Jerome",
        "Floyd",
        "Leo",
        "Alvin",
        "Tim",
        "Wesley",
        "Gordon",
        "Dean",
        "Greg",
        "Jorge",
        "Dustin",
        "Pedro",
        "Derrick",
        "Dan",
        "Lewis",
        "Zachary",
        "Corey",
        "Herman",
        "Maurice",
        "Vernon",
        "Roberto",
        "Clyde",
        "Glen",
        "Hector",
        "Shane",
        "Ricardo",
        "Sam",
        "Rick",
        "Lester",
        "Brent",
        "Ramon",
        "Charlie",
        "Tyler",
        "Gilbert",
        "Gene",
        "Marc",
        "Reginald",
        "Ruben",
        "Brett",
        "Angel",
        "Nathaniel",
        "Rafael",
        "Leslie",
        "Edgar",
        "Milton",
        "Raul",
        "Ben",
        "Chester",
        "Cecil",
        "Duane",
        "Franklin",
        "Andre",
        "Elmer",
        "Brad",
        "Gabriel",
        "Ron",
        "Mitchell",
        "Roland",
        "Arnold",
        "Harvey",
        "Jared",
        "Adrian",
        "Karl",
        "Cory",
        "Claude",
        "Erik",
        "Darryl",
        "Jamie",
        "Neil",
        "Jessie",
        "Christian",
        "Javier",
        "Fernando",
        "Clinton",
        "Ted",
        "Mathew",
        "Tyrone",
        "Darren",
        "Lonnie",
        "Lance",
        "Cody",
        "Julio",
        "Kelly",
        "Kurt",
        "Allan",
        "Nelson",
        "Guy",
        "Clayton",
        "Hugh",
        "Max",
        "Dwayne",
        "Dwight",
        "Armando",
        "Felix",
        "Jimmie",
        "Everett",
        "Jordan",
        "Ian",
        "Wallace",
        "Ken",
        "Bob",
        "Jaime",
        "Casey",
        "Alfredo",
        "Alberto",
        "Dave",
        "Ivan",
        "Johnnie",
        "Sidney",
        "Byron",
        "Julian",
        "Isaac",
        "Morris",
        "Clifton",
        "Willard",
        "Daryl",
        "Ross",
        "Virgil",
        "Andy",
        "Marshall",
        "Salvador",
        "Perry",
        "Kirk",
        "Sergio",
        "Marion",
        "Tracy",
        "Seth",
        "Kent",
        "Terrance",
        "Rene",
        "Eduardo",
        "Terrence",
        "Enrique",
        "Freddie",
        "Wade",
    ];

    private static allHumanFemaleNames: string[] = [
        "Mary",
        "Patricia",
        "Linda",
        "Barbara",
        "Elizabeth",
        "Jennifer",
        "Maria",
        "Susan",
        "Margaret",
        "Dorothy",
        "Lisa",
        "Nancy",
        "Karen",
        "Betty",
        "Helen",
        "Sandra",
        "Donna",
        "Carol",
        "Ruth",
        "Sharon",
        "Michelle",
        "Laura",
        "Sarah",
        "Kimberly",
        "Deborah",
        "Jessica",
        "Shirley",
        "Cynthia",
        "Angela",
        "Melissa",
        "Brenda",
        "Amy",
        "Anna",
        "Rebecca",
        "Virginia",
        "Kathleen",
        "Pamela",
        "Martha",
        "Debra",
        "Amanda",
        "Stephanie",
        "Carolyn",
        "Christine",
        "Marie",
        "Janet",
        "Catherine",
        "Frances",
        "Ann",
        "Joyce",
        "Diane",
        "Alice",
        "Julie",
        "Heather",
        "Teresa",
        "Doris",
        "Gloria",
        "Evelyn",
        "Jean",
        "Cheryl",
        "Mildred",
        "Katherine",
        "Joan",
        "Ashley",
        "Judith",
        "Rose",
        "Janice",
        "Kelly",
        "Nicole",
        "Judy",
        "Christina",
        "Kathy",
        "Theresa",
        "Beverly",
        "Denise",
        "Tammy",
        "Irene",
        "Jane",
        "Lori",
        "Rachel",
        "Marilyn",
        "Andrea",
        "Kathryn",
        "Louise",
        "Sara",
        "Anne",
        "Jacqueline",
        "Wanda",
        "Bonnie",
        "Julia",
        "Ruby",
        "Lois",
        "Tina",
        "Phyllis",
        "Norma",
        "Paula",
        "Diana",
        "Annie",
        "Lillian",
        "Emily",
        "Robin",
        "Peggy",
        "Crystal",
        "Gladys",
        "Rita",
        "Dawn",
        "Connie",
        "Florence",
        "Tracy",
        "Edna",
        "Tiffany",
        "Carmen",
        "Rosa",
        "Cindy",
        "Grace",
        "Wendy",
        "Victoria",
        "Edith",
        "Kim",
        "Sherry",
        "Sylvia",
        "Josephine",
        "Thelma",
        "Shannon",
        "Sheila",
        "Ethel",
        "Ellen",
        "Elaine",
        "Marjorie",
        "Carrie",
        "Charlotte",
        "Monica",
        "Esther",
        "Pauline",
        "Emma",
        "Juanita",
        "Anita",
        "Rhonda",
        "Hazel",
        "Amber",
        "Eva",
        "Debbie",
        "April",
        "Leslie",
        "Clara",
        "Lucille",
        "Jamie",
        "Joanne",
        "Eleanor",
        "Valerie",
        "Danielle",
        "Megan",
        "Alicia",
        "Suzanne",
        "Michele",
        "Gail",
        "Bertha",
        "Darlene",
        "Veronica",
        "Jill",
        "Erin",
        "Geraldine",
        "Lauren",
        "Cathy",
        "Joann",
        "Lorraine",
        "Lynn",
        "Sally",
        "Regina",
        "Erica",
        "Beatrice",
        "Dolores",
        "Bernice",
        "Audrey",
        "Yvonne",
        "Annette",
        "June",
        "Samantha",
        "Marion",
        "Dana",
        "Stacy",
        "Ana",
        "Renee",
        "Ida",
        "Vivian",
        "Roberta",
        "Holly",
        "Brittany",
        "Melanie",
        "Loretta",
        "Yolanda",
        "Jeanette",
        "Laurie",
        "Katie",
        "Kristen",
        "Vanessa",
        "Alma",
        "Sue",
        "Elsie",
        "Beth",
        "Jeanne",
        "Vicki",
        "Carla",
        "Tara",
        "Rosemary",
        "Eileen",
        "Terri",
        "Gertrude",
        "Lucy",
        "Tonya",
        "Ella",
        "Stacey",
        "Wilma",
        "Gina",
        "Kristin",
        "Jessie",
        "Natalie",
        "Agnes",
        "Vera",
        "Willie",
        "Charlene",
        "Bessie",
        "Delores",
        "Melinda",
        "Pearl",
        "Arlene",
        "Maureen",
        "Colleen",
        "Allison",
        "Tamara",
        "Joy",
        "Georgia",
        "Constance",
        "Lillie",
        "Claudia",
        "Jackie",
        "Marcia",
        "Tanya",
        "Nellie",
        "Minnie",
        "Marlene",
        "Heidi",
        "Glenda",
        "Lydia",
        "Viola",
        "Courtney",
        "Marian",
        "Stella",
        "Caroline",
        "Dora",
        "Jo",
        "Vickie",
        "Mattie",
        "Terry",
        "Maxine",
        "Irma",
        "Mabel",
        "Marsha",
        "Myrtle",
        "Lena",
        "Christy",
        "Deanna",
        "Patsy",
        "Hilda",
        "Gwendolyn",
        "Jennie",
        "Nora",
        "Margie",
        "Nina",
        "Cassandra",
        "Leah",
        "Penny",
        "Kay",
        "Priscilla",
        "Naomi",
        "Carole",
        "Brandy",
        "Olga",
        "Billie",
        "Dianne",
        "Tracey",
        "Leona",
        "Jenny",
        "Felicia",
        "Sonia",
        "Miriam",
        "Velma",
        "Becky",
        "Bobbie",
        "Violet",
        "Kristina",
        "Toni",
        "Misty",
        "Mae",
        "Shelly",
        "Daisy",
        "Ramona",
        "Sherri",
        "Erika",
        "Katrina",
        "Claire",
    ];

    private static allHumanLastNames: string[] = [
        "Smith",
        "Johnson",
        "Williams",
        "Brown",
        "Jones",
        "Garcia",
        "Miller",
        "Davis",
        "Rodriguez",
        "Martinez",
        "Hernandez",
        "Lopez",
        "Gonzales",
        "Wilson",
        "Anderson",
        "Thomas",
        "Taylor",
        "Moore",
        "Jackson",
        "Martin",
        "Lee",
        "Perez",
        "Thompson",
        "White",
        "Harris",
        "Sanchez",
        "Clark",
        "Ramirez",
        "Lewis",
        "Robinson",
        "Walker",
        "Young",
        "Allen",
        "King",
        "Wright",
        "Scott",
        "Torres",
        "Nguyen",
        "Hill",
        "Flores",
        "Green",
        "Adams",
        "Nelson",
        "Baker",
        "Hall",
        "Rivera",
        "Campbell",
        "Mitchell",
        "Carter",
        "Roberts",
        "Gomez",
        "Phillips",
        "Evans",
        "Turner",
        "Diaz",
        "Parker",
        "Cruz",
        "Edwards",
        "Collins",
        "Reyes",
        "Stewart",
        "Morris",
        "Morales",
        "Murphy",
        "Cook",
        "Rogers",
        "Gutierrez",
        "Ortiz",
        "Morgan",
        "Cooper",
        "Peterson",
        "Bailey",
        "Reed",
        "Kelly",
        "Howard",
        "Ramos",
        "Kim",
        "Cox",
        "Ward",
        "Richardson",
        "Watson",
        "Brooks",
        "Chavez",
        "Wood",
        "James",
        "Bennet",
        "Gray",
        "Mendoza",
        "Ruiz",
        "Hughes",
        "Price",
        "Alvarez",
        "Castillo",
        "Sanders",
        "Patel",
        "Myers",
        "Long",
        "Ross",
        "Foster",
        "Jimenez",
    ];
}