import { Module } from 'vuex';
import { Game } from '@/logic/Zoinks/Games/Game';
import { GameMechanic } from '@/logic/Zoinks/Games/GameMechanic';
import { GameTheme } from '@/logic/Zoinks/Games/GameTheme';
import { GameStatus } from '@/logic/Zoinks/Games/GameStatus';
import { PhasePortionEmployeeData } from '@/logic/Zoinks/Games/PhasePortionEmployeeData';
import { PhaseMeta } from '@/logic/Zoinks/Games/PhaseMeta';
import { PhaseStartTimeData } from '@/logic/Zoinks/Games/PhaseStartTimeData';
import { Utils } from '@/logic/Zoinks/General/Utils';
import { PhaseProgressData } from '@/logic/Zoinks/Games/PhaseProgressData';
import { MessageTrigger } from '@/logic/Zoinks/Messages/MessageTrigger';
import { MessageTriggerEventType } from '@/logic/Zoinks/Messages/MessageTriggerEventType';

export interface GamesState {
  allowMultipleActiveGames: boolean,
  activeGames: Game[],
  archivedGames: Game[],
  allThemes: GameTheme[],
  allMechanics: GameMechanic[],
  phaseProgress: number,
}

export type UpdateName = { id: number, newValue: string };
export type UpdateStatus = { id: number, newValue: GameStatus };
export type UpdateMechanic = { id: number, newValue: GameMechanic };
export type UpdateTheme = { id: number, newValue: GameTheme };

const gamesModule: Module<GamesState, any> = {
  state: {
    allowMultipleActiveGames: false,
    activeGames: [],
    archivedGames: [],
    allThemes: GameTheme.getAllThemes(),
    allMechanics: GameMechanic.getAllMechanics(),
    phaseProgress: 0,
  },
  mutations: {
    addActiveGame(state, game: Game) {
      state.activeGames.push(game);
    },
    cancelActiveGame(state, gameId: number) {
      state.activeGames.splice(state.activeGames.findIndex(g => g.id === gameId), 1);
    },
    setGameName(state, update: UpdateName) {
      state.activeGames.find(g => g.id === update.id)!.name = update.newValue;
    },
    setGameStatus(state, update: UpdateStatus) {
      const game = state.activeGames.find(g => g.id === update.id);
      if (game && game.status !== update.newValue) {
        game.status = update.newValue;
        state.phaseProgress = 0;
        const inProgressPhaseNumber = PhaseMeta.getInProgressPhaseNumberByGameStatus(game.status);
        if (inProgressPhaseNumber) {
          game.getPhase(inProgressPhaseNumber).startTime = Date.now();
        }
        if ((update.newValue === GameStatus.Abandoned) || (update.newValue === GameStatus.Completed)) {
          state.activeGames.splice(state.activeGames.indexOf(game), 1);
          state.archivedGames.push(game);
        }
      }
    },
    setGameMechanic(state, update: UpdateMechanic) {
      state.activeGames.find(g => g.id === update.id)!.mechanic = update.newValue;
    },
    setGameTheme(state, update: UpdateTheme) {
      state.activeGames.find(g => g.id === update.id)!.theme = update.newValue;
    },
    setPhasePortionEmployee(state, phasePortionEmployeeData: PhasePortionEmployeeData) {
      state.activeGames.find(g => g.id === phasePortionEmployeeData.gameId)!
        .getPhase(phasePortionEmployeeData.phaseNumber)!
        .getPhasePortion(phasePortionEmployeeData.phasePortionNumber)!
        .assignedEmployeeId = phasePortionEmployeeData.employeeId;
    },
    setPhaseStartTime(state, phaseStartTimeData: PhaseStartTimeData) {
      state.activeGames.find(g => g.id === phaseStartTimeData.gameId)!
        .getPhase(phaseStartTimeData.phaseNumber)!
        .startTime = phaseStartTimeData.startTime;
    },
    setPhaseProgress(state, phaseProgressData: PhaseProgressData) {
      state.activeGames.find(g => g.id === phaseProgressData.gameId)!
        .getPhase(phaseProgressData.phaseNumber)!
        .progress = phaseProgressData.progress;
    },
  },
  actions: {
    gamesTick( { state, commit, rootState }, now: number) {
      for (const game of state.activeGames) {
        const inProgressPhaseNumber = PhaseMeta.getInProgressPhaseNumberByGameStatus(game.status);
        if (inProgressPhaseNumber) {
          const phase = game.getPhase(inProgressPhaseNumber);
          if (now < phase.startTime) {
            // This should never happen
            commit("setPhaseStartTime", new PhaseStartTimeData(game.id, inProgressPhaseNumber, now));
          }
          const updatedProgress = Utils.GetProgress(phase.startTime, now, game.getPhase(inProgressPhaseNumber).phaseMeta.duration);
          commit ("setPhaseProgress", new PhaseProgressData(game.id, inProgressPhaseNumber, updatedProgress));
  
          if (updatedProgress === 1) {
            // move to next phase
            const nextGameStatus = PhaseMeta.getNextGameStatus(game.status);
            commit("setGameStatus", { id: game.id, newValue: nextGameStatus });

            // trigger any messages:
            if (nextGameStatus === GameStatus.Completed) {
              MessageTrigger.fireConditions(MessageTriggerEventType.GameComplete, rootState, commit);
            } else {
              MessageTrigger.fireConditions(MessageTriggerEventType.GamePhaseComplete, rootState, commit);
            }
          }
        }
      }
    },
    removeEmployeeFromActivePhase( { state, commit }, employeeId: number) {
      for (const game of state.activeGames) {
        const phaseNumber = PhaseMeta.getSetupPhaseNumberByGameStatus(game.status);
        if (phaseNumber) {
          for (let phasePortionNumber = 1; phasePortionNumber <= 3; phasePortionNumber++) {
            if (game.getPhase(phaseNumber).getPhasePortion(phasePortionNumber).assignedEmployeeId === employeeId) {
              commit("setPhasePortionEmployee", new PhasePortionEmployeeData(game.id, phaseNumber, phasePortionNumber, 0));
            }
          }
        }
      }
    },
  },
}

export default gamesModule;