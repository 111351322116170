export enum GameStatus {
    None = 0,                   // Just a default status that a game should never have
    Setup = 10,                 // Naming the game, etc.
    Phase1Setup = 20,           // Setup employees/features for planning phase
    Phase1InProgress = 30,      // Planning phase in progress
    Phase2Setup = 40,           // Setup employees/features for production phase
    Phase2InProgress = 50,      // Production phase in progress
    Phase3Setup = 60,           // Setup employees/features for testing phase
    Phase3InProgress = 70,      // Testing phase in progress
    Abandoned = 80,             // The game was started but was abandoned
    Completed = 90,             // The game is done
}