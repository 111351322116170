import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { trackRouter } from "vue-gtag-next";
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:
    {
      title: "Autosaved; A place for Christian geeks",
      description: "Autosaved is a website where Christian nerds Robbie Mahair and Chris Krentz, a couple of Christian nerds, create Minecraft tools, Factorio blueprints, fiction books, and whatever else we feel like creating!"
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta:
    {
      title: "About Autosaved",
      description: "Learn everything you never wanted to know about Chris Krentz and Robbie Mahair!"
    },
  },
  {
    path: '/spritecraft',
    name: 'Spritecraft',
    component: () => import(/* webpackChunkName: "spritecraft" */ '../views/SpritecraftWeb.vue'),
    meta:
    {
      title: "Spritecraft: Minecraft Pixel Art Generator",
      description: "Spritecraft can quickly and easily convert any image into Minecraft pixel art. Supports nearly all Minecraft blocks."
    },
  },
  {
    path: '/themostdifficultgame',
    name: 'TheMostDifficultGame',
    component: () => import(/* webpackChunkName: "themostdifficultgame" */ '../views/TheMostDifficultGame.vue'),
    meta:
    {
      title: "The Most Difficult Game",
      description: "Figuring out the game is part of the game. Can you beat the first level?"
    },
  },
  {
    path: '/zoinks',
    name: 'TheZoinksMakeAGame',
    component: () => import(/* webpackChunkName: "zoinks" */ '../views/Zoinks/Main.vue'),
    meta:
    {
      title: "The Zoinks Make a Game",
      description: "A perfectly normal human game company run by perfectly normal humans"
    },
  },
  {
    path: '/books',
    name: 'Books',
    component: () => import(/* webpackChunkName: "books" */ '../views/Books.vue'),
    meta:
    {
      title: "Books by Robbie Mahair",
      description: "Check out books written by Robbie Mahair, author of Orc Road Trip (4.8 out of 5 stars on Amazon)."
    },
  },
  {
    path: '/tshirts',
    name: 'T-Shirts',
    component: () => import(/* webpackChunkName: "books" */ '../views/TShirts.vue'),
    meta:
    {
      title: "T-Shirts by Autosaved",
      description: "Geeky or Christian t-shirts"
    },
  },
  {
    path: '/podcast',
    name: 'Podcast',
    component: () => import(/* webpackChunkName: "podcast" */ '../views/Podcast.vue'),
    meta:
    {
      title: "The Autosaved Podcast",
      description: "The Autosaved podcast is no longer being recorded, but you can check out our old episodes here."
    },
  },
  {
    path: '/factorio/blueprints',
    name: 'Factorio Blueprints',
    component: () => import(/* webpackChunkName: "blueprints" */ '../views/Factorio/Blueprints.vue'),
    meta: { title: "Factorio Blueprint Library" },
  },
  {
    path: '/factorio/blueprintsv1',
    name: 'Factorio Blueprints Version 1',
    component: () => import(/* webpackChunkName: "blueprintsv1" */ '../views/Factorio/BlueprintsV1.vue'),
    meta: { title: "Factorio Blueprint Library Version 1" },
  },
  {
    path: '/factorio/factoryplanner',
    name: 'FactoryPlanner',
    component: () => import(/* webpackChunkName: "factoryplanner" */ '../views/Factorio/FactoryPlanner.vue'),
    meta:
    {
      title: "The Factorio Factory Planner",
      description: "A somewhat difficult to use (hey ... I'm just being honest) but extremely handy tool for planning out your Factorio blueprints. Want to know how many Assembling Machine 3s you'll need to produce enough green circuits to get 1 blue circuit per second output? This will tell you!"
    },
  },
  {
    path: '/stardew-valley-first-six-days-guide',
    name: 'StardewValleyFirstSixDaysGuide',
    component: () => import(/* webpackChunkName: "factoryplanner" */ '../views/StardewValleyFirstSixDays.vue'),
    meta:
    {
      title: "Stardew Valley First Six Days Guide (No Pants)",
      description: "The ultimate guide to optimizing your first six days of Stardew Valley with no pants on (no pants on is optional)."
    },
  },
  {
    path: '/:pathMatch(books/.*)*',
    redirect: '/books',
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/spritecraft',
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

trackRouter(router);

export default router