import { EmployeeSearchTypeEnum } from "./EmployeeSearchTypeEnum";
import { RecruitingLevel } from "./RecuitingLevel";

export class EmployeeSearchType {
	public readonly name: string;
    public readonly description: string;
    public readonly duration: number;
    public readonly searchType: EmployeeSearchTypeEnum;
    public readonly requiredRecruitingLevel: RecruitingLevel;

	private constructor(name: string, description: string, duration: number, searchType: EmployeeSearchTypeEnum, requiredRecruitingLevel: RecruitingLevel) {
        this.name = name;
        this.description = description;
        this.duration = duration;
        this.searchType = searchType;
        this.requiredRecruitingLevel = requiredRecruitingLevel;
	}

    public static readonly StreetCorner = new EmployeeSearchType("Check the street corner", 'Shouting, "Anyone, like, you know, wanna work for me?" ...', 2, EmployeeSearchTypeEnum.StreetCorner, RecruitingLevel.None);
    public static readonly CheckWithFriends = new EmployeeSearchType("Check with your friends", 'Asking your friends if they know anyone who wants a job ...', 6, EmployeeSearchTypeEnum.CheckWithFriends, RecruitingLevel.None);
    public static readonly LocalAd = new EmployeeSearchType("Local ad", "Waiting for responses from your employment ad ...", 15, EmployeeSearchTypeEnum.LocalAd, RecruitingLevel.Basic);

    public static getEmployeeSearchTypesMap(): Map<EmployeeSearchTypeEnum, EmployeeSearchType> {
        const map = new Map<EmployeeSearchTypeEnum, EmployeeSearchType>();
        this.addToMap(EmployeeSearchType.StreetCorner, map);
        this.addToMap(EmployeeSearchType.CheckWithFriends, map);
        this.addToMap(EmployeeSearchType.LocalAd, map);
        return map;
    }

    private static addToMap(toAdd: EmployeeSearchType, map: Map<EmployeeSearchTypeEnum, EmployeeSearchType>) {
        map.set(toAdd.searchType, toAdd);
    }
}