export class PhasePortionEmployeeData {
    public gameId: number;
    public phaseNumber: number;
    public phasePortionNumber: number;
    public employeeId: number | null;

    public constructor(gameId: number, phaseNumber: number, phasePortionNumber: number, employeeId: number | null) {
        this.gameId = gameId;
        this.phaseNumber = phaseNumber;
        this.phasePortionNumber = phasePortionNumber;
        this.employeeId = employeeId;
    }
}