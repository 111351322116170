import { Message } from '@/logic/Zoinks/Messages/Message';
import { Module } from 'vuex';

export interface MessagesState {
  currentMessages: Message[],
  archivedMessages: Message[],
}

const messagesModule: Module<MessagesState, any> = {
  state: {
    currentMessages: [],
    archivedMessages: [],
  },
  mutations: {
    addMessage(state, message: Message) {
      state.currentMessages.push(message);
    },
    messageRead(state, message: Message) {
      const index = state.currentMessages.indexOf(message);
      if (index > -1) {
        state.currentMessages.splice(index, 1);
        state.archivedMessages.push(message);
      }
    }
  },
  actions: {
    messagesTick( { state, commit }, now: number) {
      // TODO: Maybe something here? Not sure if it's needed.
    },
  },
}

export default messagesModule;