import { GameStatus } from "./GameStatus";
import { PhasePortionMeta } from "./PhasePortionMeta";

export class PhaseMeta {
    public name: string;
    public duration: number;
    public devPortion: PhasePortionMeta;
    public designPortion: PhasePortionMeta;
    public writingPortion: PhasePortionMeta;

    private constructor(name: string, duration: number, devPortion: PhasePortionMeta, designPortion: PhasePortionMeta, writingPortion: PhasePortionMeta) {
        this.name = name;
        this.duration = duration;
        this.devPortion = devPortion;
        this.designPortion = designPortion;
        this.writingPortion = writingPortion;
    }

    public static readonly Phase1 = new PhaseMeta("Planning", 5, PhasePortionMeta.Phase1_DevPortion, PhasePortionMeta.Phase1_DesignPortion, PhasePortionMeta.Phase1_WritingPortion);
    public static readonly Phase2 = new PhaseMeta("Production", 5, PhasePortionMeta.Phase2_DevPortion, PhasePortionMeta.Phase2_DesignPortion, PhasePortionMeta.Phase2_WritingPortion);
    public static readonly Phase3 = new PhaseMeta("Testing", 5, PhasePortionMeta.Phase3_DevPortion, PhasePortionMeta.Phase3_DesignPortion, PhasePortionMeta.Phase3_WritingPortion);

    public static getPhaseNameByGameStatus(gameStatus: GameStatus): string {
        return PhaseMeta.getPhaseNameByNumber(PhaseMeta.getPhaseNumberByGameStatus(gameStatus));
    }

    public static getPhaseNumberByGameStatus(gameStatus: GameStatus): number {
        if (gameStatus === GameStatus.Phase1Setup || gameStatus === GameStatus.Phase1InProgress) {
            return 1;
        } else if (gameStatus === GameStatus.Phase2Setup || gameStatus === GameStatus.Phase2InProgress) {
            return 2;
        } else if (gameStatus === GameStatus.Phase3Setup || gameStatus === GameStatus.Phase3InProgress) {
            return 3;
        } else {
            return 0;
        }
    }

    public static getInProgressPhaseNumberByGameStatus(gameStatus: GameStatus): number {
        if (gameStatus === GameStatus.Phase1InProgress) {
            return 1;
        } else if (gameStatus === GameStatus.Phase2InProgress) {
            return 2;
        } else if (gameStatus === GameStatus.Phase3InProgress) {
            return 3;
        } else {
            return 0;
        }
    }

    public static getSetupPhaseNumberByGameStatus(gameStatus: GameStatus): number {
        if (gameStatus === GameStatus.Phase1Setup) {
            return 1;
        } else if (gameStatus === GameStatus.Phase2Setup) {
            return 2;
        } else if (gameStatus === GameStatus.Phase3Setup) {
            return 3;
        } else {
            return 0;
        }
    }

    public static getPhaseNameByNumber(phaseNumber: number): string {
        if (phaseNumber === 1) {
            return PhaseMeta.Phase1.name;
        } else if (phaseNumber === 2) {
            return PhaseMeta.Phase2.name;
        } else if (phaseNumber === 3) {
            return PhaseMeta.Phase3.name;
        } else {
            return "";
        }
    }

    public static getNextGameStatus(gameStatus: GameStatus): GameStatus {
        if (gameStatus === GameStatus.Setup) {
            return GameStatus.Phase1Setup;
        } else if (gameStatus === GameStatus.Phase1Setup) {
            return GameStatus.Phase1InProgress;
        } else if (gameStatus === GameStatus.Phase1InProgress) {
            return GameStatus.Phase2Setup;
        } else if (gameStatus === GameStatus.Phase2Setup) {
            return GameStatus.Phase2InProgress;
        } else if (gameStatus === GameStatus.Phase2InProgress) {
            return GameStatus.Phase3Setup;
        } else if (gameStatus === GameStatus.Phase3Setup) {
            return GameStatus.Phase3InProgress;
        } else if (gameStatus === GameStatus.Phase3InProgress) {
            return GameStatus.Completed;
        }
        return GameStatus.None;
    }
}