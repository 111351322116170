export class PhaseStartTimeData {
    public gameId: number;
    public phaseNumber: number;
    public startTime: number;

    public constructor(gameId: number, phaseNumber: number, startTime: number) {
        this.gameId = gameId;
        this.phaseNumber = phaseNumber;
        this.startTime = startTime;
    }
}