import { Constants } from '@/logic/Zoinks/General/Constants';
import { Module } from 'vuex';

export interface GeneralState {
  runGame: boolean,
}

const generalModule: Module<GeneralState, any> = {
  state: {
    runGame: false,
  },
  mutations: {
    setRunGame(state, runGame: boolean) {
      state.runGame = runGame;
    },
  },
  actions: {
    async startGameTimer({ state, commit, dispatch, rootState }) {
        // TODO: This is surprisingly bad. With a low enough interval it takes MUCH longer. I think maybe a more accurate timer would be based on elapsed time?
        // https://stackoverflow.com/questions/29971898/how-to-create-an-accurate-timer-in-javascript
        commit("setRunGame", true);
        while (state.runGame) {
            await new Promise(resolve => setTimeout(resolve, Constants.GAME_TICK_INTERVAL));
            const now = Date.now();
            dispatch("employeesTick", now);
            dispatch("gamesTick", now);
            dispatch("messagesTick", now);
        }
    },
  },
}

export default generalModule;