export class Constants {
    //public static readonly GAME_TICK_INTERVAL = 200; // 5 ticks per second -> this feels about right if you aren't going for "smooth"
    public static readonly GAME_TICK_INTERVAL = 10; // 100 ticks per second

    public static readonly SKILL_MAX = 100;
    public static readonly SKILL_MIN = 1;
    public static readonly POTENTIAL_MAX = 10;
    public static readonly POTENTIAL_MIN = 1;

    public static readonly ZOINK_SKILL_MODIFIER = .67;

    public static readonly SKILL_MEAN_STREETCORNER = 10;
    public static readonly SKILL_STDEV_STREETCORNER = 15;
    public static readonly POTENTIAL_MEAN_STREETCORNER = 2;
    public static readonly POTENTIAL_STDEV_STREETCORNER = 2;

    public static readonly SKILL_MEAN_FRIENDS = 20;
    public static readonly SKILL_STDEV_FRIENDS = 15;
    public static readonly POTENTIAL_MEAN_FRIENDS = 2;
    public static readonly POTENTIAL_STDEV_FRIENDS = 2;
}